<template>
  <b-card>
    <g-table
      :columns="tableColumns"
      :items="items"
      :createButton="{ visiable: true , permission: 'addUsers' }"
      class="imageTable"
      @on-create="
        () => {
          $router.push({ name: 'new-users' });
        }
      "
    >
    <template #actions="{ item }">
        <div class="text-nowrap">
          <b-button
             data-action-type="preview"
            v-b-tooltip.hover.top="$t('preview')"
            variant="custom"
            class="btn-icon"
            size="sm"
            v-permission=" 'editUsers'"
           @click="edit(item)"
          >
            <feather-icon
              icon="EyeIcon"
            class="mx-1 clickable "
            :hidden="true"
              :id="`invoice-row-${item.id}-prev-icon`"
            />
          </b-button>
          <b-button
             data-action-type="edit"
            v-b-tooltip.hover.top="$t('edit')"
            variant="flat-secondary"
            class="btn-icon"
            size="sm"
            v-permission=" 'editUsers'"
           @click="edit(item)"
          >
            <feather-icon
              icon="EditIcon"
              class="clickable"
              :id="`invoice-row-${item.id}-preview-icon`"
            />
          </b-button>
             <b-button
             data-action-type="delete"
            v-b-tooltip.hover.top="$t('delete')"
            variant="flat-danger"
            class="btn-icon"
            size="sm"
            v-permission=" 'deleteUsers'"
           @click="remove(item)"
          >
            <feather-icon
              icon="TrashIcon"
              stroke="red"
              class="danger"
              :id="`invoice-row-${item.id}-delete-icon`"
            />
          </b-button>
        </div>
      </template>
    </g-table>
  </b-card>
</template>

<script>
import { mapActions } from 'vuex';
import GTable from '@/pages/Shared/Table.vue';

export default {
  components: {
    GTable,
  },
  data() {
    return {
      items: [],
    };
  },
  computed: {
    tableColumns() {
      return [
        {
          key: 'code',
          label: this.$t('code'),
        },
        {
          key: 'userName',
          label: this.$t('userName'),
          sortable: true,
        },
        {
          key: 'isAdmin',
          label: this.$t('jopDesc'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.isAdmin) return `<span class="badge badge-light-primary">${this.$t('isAdmin')}</span>`;
            return `<span class="badge badge-light-warning">${this.$t('user')}</span>`;
          },
        },
        {
          key: 'isActive',
          label: this.$t('status'),
          sortable: true,
          formatter: (key, value, item) => {
            if (item.isActive) return `<span class="badge badge-light-success">${this.$t('active')}</span>`;
            return `<span class="badge badge-light-danger">${this.$t('notActive')}</span>`;
          },
        },
        {
          key: 'actions',
        },
      ];
    },
  },
  mounted() {
    this.get({ url: 'Users' }).then((data) => {
      this.items = data;
    }).catch((data) => {
        if (data.response.status === 403) {
          this.logout();
        }
     });
  },
  methods: {
    ...mapActions({
      logout: 'auth/logout'
    }),
     edit(item) {
      this.$router.push({
        name: 'edit-users',
        params: { id: item.id },
      });
    },
    remove(item) {
      this.confirmAction(
        {
          text: this.$t('areYouSureYouWantToDelete'),
        },
        () => {
          this.delete({ url: 'users', id: item.id })
            .then(() => {
              this.doneAlert({ text: this.$t('deletedSuccessfully') });
              this.items = this.items.filter((u) => u.id !== item.id);
            })
            .catch((data) => {
              this.doneAlert({ text: data.data.detail, type: 'error' });
            });
        }
      );
    },
  },
};
</script>

<style>
.imageTable .table td, .imageTable .table th{
  text-align: left !important;
}
</style>
